export const redirections = [
  {
    from: 'ProfileHubContactsIndividual',
    to: { name: 'ProfileHubContactsList' },
  },
  {
    from: 'TouchpointsEdition',
    to: { name: 'TouchpointsList' },
  },
]
