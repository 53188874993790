export const CRISP_ID = 'crisp-chatbox'

export function displayCrisp() {
  document.getElementById(CRISP_ID)?.setAttribute('data-hidden', 'false')
}

export function hideCrisp() {
  document.getElementById(CRISP_ID)?.setAttribute('data-hidden', 'true')
}

export function createCrispScript() {
  let tag = document.createElement('script')
  tag.setAttribute('src', '/crisp-chat.js')
  document.head.appendChild(tag)
}
