<template>
  <v-navigation-drawer app dark color="navigation" :value="drawer" @input="toggleDrawer">
    <div class="tw-flex tw-items-center tw-justify-center tw-px-4 tw-h-14">
      <v-skeleton-loader v-if="loading" type="image" width="100%" height="32" />
      <v-autocomplete
        v-else-if="brands.length > 1"
        :value="currentBrand"
        :items="brands"
        item-text="name"
        item-value="uuid"
        solo
        flat
        light
        outlined
        dense
        hide-details
        auto-select-first
        :menu-props="{ closeOnContentClick: true }"
        :no-data-text="$t('label.noData')"
        return-object
        @input="onInput"
        :readonly="disabledBrandSelect"
      ></v-autocomplete>
      <v-img
        alt="GTR Suite Logo"
        contain
        :src="require('@/assets/images/logo-dark.svg')"
        height="32"
        position="center left"
        v-else
      />
    </div>

    <div v-if="loading || menuing" class="tw-px-4">
      <template v-for="(n, i) in 3">
        <v-skeleton-loader type="image" :key="`heading-${i}`" class="tw-mt-10 tw-rounded-2xl" height="14" width="50%" />
        <template v-for="(n, j) in 3">
          <v-skeleton-loader type="image" :key="`text-${i}-${j}`" height="16" class="tw-mt-7 tw-rounded-2xl" />
        </template>
      </template>
    </div>
    <v-list nav dense class="tw-mb-2" v-else>
      <template v-for="(n, i) in navigation">
        <v-subheader class="tw-mt-6 primary--text tw-font-bold" :key="`subheader-${i}`">
          {{ groupLabel(n.key).toUpperCase() }}
        </v-subheader>
        <v-list-item-group :key="`item-group-${i}`" color="primary">
          <template v-for="item in n.items">
            <v-list-item
              v-if="isExternalLink(item) || isInternalLink(item)"
              :key="item.key"
              :href="menuRoute(n.key, item)"
            >
              <v-list-item-icon class="tw-mr-4">
                <v-icon>{{ menuIcon(n.key, item.key) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ menuLabel(n.key, item.key) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="menuRoute(n.key, item)" :key="item.key" :to="menuRoute(n.key, item)">
              <v-list-item-icon class="tw-mr-4">
                <v-icon>{{ menuIcon(n.key, item.key) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ menuLabel(n.key, item.key) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { menus } from '@/config/menus.config'
import { getUserToken, isInternalLink, isExternalLink } from '@/utils/auth.util'

export default {
  props: {
    drawer: {
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    menuing: {
      type: Boolean,
      required: true,
    },
    navigation: {
      type: Array,
      required: true,
    },
    currentModule: {
      type: Object,
      required: false,
      default: () => {},
    },
    brands: {
      type: Array,
      require: false,
      default: () => [],
    },
    currentBrand: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    isInternalLink,
    isExternalLink,
  }),
  computed: {
    disabledBrandSelect() {
      return this.$route.name === 'UserEdit'
    },
  },
  methods: {
    toggleDrawer(bool) {
      this.$emit('toggleDrawer', bool)
    },
    groupLabel(groupKey) {
      return this.$t(`navigation.${groupKey}.label`)
    },
    menuLabel(groupKey, menuKey) {
      return this.$t(`navigation.${groupKey}.item.${menuKey}`)
    },
    menuIcon(groupKey, menuKey) {
      return menus[groupKey]?.items[menuKey]?.icon
    },
    menuRoute(groupKey, menu) {
      if (isInternalLink(menu)) {
        return `${menu.url}&usertoken=${getUserToken()}`
      }
      if (isExternalLink(menu)) {
        return menu.url
      }

      if (['conversion', 'leads_management', 'forms_management'].includes(menu.key)) {
        return menus[groupKey]?.items[menu.key]?.route
          ? {
              name: menus[groupKey].items[menu.key].route,
              params: { module: this.currentModule.module, available: menu.available },
              query: { formId: this.$route.query.formId || null },
            }
          : null
      }
      return menus[groupKey]?.items[menu.key]?.route
        ? {
            name: menus[groupKey].items[menu.key].route,
            params: { module: this.currentModule.module, available: menu.available },
          }
        : null
    },
    onInput(brand) {
      this.$emit('updateBrand', brand)
    },
  },
}
</script>
