<template>
  <v-skeleton-loader v-if="loading" type="image" width="32" height="32" class="tw-rounded-full tw--ml-1 sm:tw-ml-1" />
  <v-btn icon class="sm:tw--ml-1" @click="$emit('toggleDrawer')" v-else>
    <v-icon>{{ icons.mdiMenu }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiMenu } from '@mdi/js'

export default {
  name: 'HeaderMenu',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiMenu,
    },
  }),
}
</script>
