import {
  mdiHomeAnalytics,
  mdiImport,
  mdiAccountGroupOutline,
  mdiStarCogOutline,
  mdiPlaylistStar,
  mdiChartBoxOutline,
  mdiExportVariant,
  mdiQrcode,
  mdiPlus,
  mdiStoreCogOutline,
  mdiStoreClockOutline,
  mdiBullseyeArrow,
  mdiCogOutline,
  mdiFormSelect,
  mdiFinance,
  mdiRadar,
  mdiCircleEditOutline,
} from '@mdi/js'

export const menus = Object.freeze({
  profiles: {
    items: {
      profiles_dashboard: {
        icon: mdiHomeAnalytics,
        route: 'ProfileHubDashboard',
      },
      profiles_import: {
        icon: mdiImport,
        route: 'ProfileHubImport',
      },
      profiles_views: {
        icon: mdiAccountGroupOutline,
        route: 'ProfileHubContactsList',
      },
      profiles_export: {
        icon: mdiExportVariant,
        route: 'ProfileHubExport',
      },
      profiles_criteria: {
        icon: mdiStoreCogOutline,
        route: 'ProfileHubCriteriaList',
      },
      profiles_mapping: {
        icon: mdiStoreClockOutline,
        route: 'ProfileHubCriteriaMapping',
      },
    },
  },
  review_replies: {
    items: {
      all_reviews: {
        icon: mdiPlaylistStar,
        route: 'AutoReplyReviews',
      },
      semantic_analysis: {
        icon: mdiChartBoxOutline,
        route: 'ReviewRepliesSemantic',
      },
      settings: {
        icon: mdiStarCogOutline,
        route: 'AutoReplySettings',
      },
    },
  },
  touchpoints: {
    items: {
      touchpoints_management: {
        icon: mdiQrcode,
        route: 'TouchpointsList',
      },
      touchpoints_creation: {
        icon: mdiPlus,
        route: 'TouchpointsCreation',
      },
    },
  },
  platform_leads: {
    items: {
      leads_management: {
        icon: mdiBullseyeArrow,
        route: 'PlatformLeads',
      },
      forms_management: {
        icon: mdiFormSelect,
        route: 'PlatformForm',
      },
      conversion: {
        icon: mdiFinance,
        route: 'PlatformConversion',
      },
    },
  },
  platform_opportunity: {
    items: {
      opportunity_map: {
        icon: mdiRadar,
        route: 'PlatformOpportunity',
      },
      opportunity_configuration: {
        icon: mdiCircleEditOutline,
        route: 'PlatformOpportunityConfiguration',
      },
    },
  },
  module_settings: {
    items: {
      users: {
        icon: mdiCogOutline,
        route: 'UserList',
      },
    },
  },
})
