<template>
  <v-skeleton-loader v-if="loading" type="image" width="32" height="32" class="tw-rounded-full tw--mr-1 sm:tw-mr-1" />
  <div class="tw--mr-3 sm:tw--mr-1" v-else>
    <v-menu offset-y left :close-on-content-click="true" max-width="300" transition="slide-y-reverse-transition">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-btn icon v-bind="attrs" v-on="{ ...menu }">
          <v-avatar size="32" color="primary" class="tw-text-white">
            <span v-if="currentUser.firstname && currentUser.lastname"> {{ userInitials }} </span>
            <v-icon v-else dark> {{ icons.mdiAccountOutline }} </v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="primary" class="tw-text-white tw-justify-center">
            <span v-if="currentUser.firstname && currentUser.lastname">{{ userInitials }}</span>
            <v-icon v-else dark size="24"> {{ icons.mdiAccountOutline }} </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="tw-font-medium">
              {{ currentUser.firstname }} {{ currentUser.lastname }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'Profile', query: { module: currentModule.module } }">
          <v-list-item-icon>
            <v-icon>{{ icons.mdiAccountCircleOutline }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('header.account.menu.profile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="toggleDarkTheme">
          <v-list-item-icon>
            <v-icon>{{ icons.mdiWeatherNight }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('header.account.menu.toggleDarkTheme') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch :input-value="$vuetify.theme.dark"></v-switch>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon class="tw-text-red-600">{{ icons.mdiPowerStandby }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="tw-text-red-600">
              {{ $t('header.account.menu.logout') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { URLS } from '@/config/urls.config'
import { mdiAccountCircleOutline, mdiWeatherNight, mdiPowerStandby, mdiAccountOutline } from '@mdi/js'

export default {
  name: 'HeaderAccount',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    currentUser: {
      required: true,
    },
  },
  data: () => {
    return {
      icons: {
        mdiAccountCircleOutline,
        mdiWeatherNight,
        mdiPowerStandby,
        mdiAccountOutline,
      },
    }
  },
  computed: {
    ...mapState({
      currentModule: state => state.backoffice.currentModule,
    }),
    userInitials() {
      if (this.currentUser.firstname && this.currentUser.lastname) {
        return `${this.currentUser.firstname.charAt(0)}${this.currentUser.lastname.charAt(0)}`
      }
      return ``
    },
    settingRoute() {
      return {
        name: 'UserList',
        params: { module: 'settings', available: true },
      }
    },
  },
  methods: {
    ...mapActions({
      userLogout: 'backoffice/userLogout',
    }),
    toggleDarkTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('dark_theme', +this.$vuetify.theme.dark)
      document.documentElement.classList.toggle('tw-dark')
    },
    async logout() {
      await this.userLogout()
      window.location.assign(`${URLS.SSO}/?sign_out=1`)
    },
    goToSettingsModule() {
      this.$emit('update', { module: 'settings', version: 'gtrsuite_v3' })
    },
  },
}
</script>
